@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&family=Noto+Sans+JP:wght@300;400;700&display=swap");

* {
  margin: 0; 
  padding: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  height: 100vh;
  height: 100dvh;
}

body {
  font-feature-settings: "pwid";
  -webkit-font-smoothing: antialiased;
  -moz-text-size-adjust: 100%;
       text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

address, caption, cite, code, em, th {
  font-style: normal;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, svg {
  vertical-align: top;
  border: 0;
}

li {
  list-style-type: none;
}

button, input, select, textarea {
  font-family: inherit;
}

fieldset {
  border: 0;
}

input[type=search] {
  border-radius: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

input[type=date] {
  line-height: 1;
}

button, [type=button], [type=reset], [type=submit], [role=button] {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

input[type=search],
input[type=date],
input[type=time] {
  background-color: #fff;
}

button, [type=reset], [type=submit] {
  -webkit-appearance: button;
     -moz-appearance: button;
          appearance: button;
}

object {
  pointer-events: none;
}

/* html {
  font-size: 6.25%;
} */

body {
  font-size: 16px;
  background: #fff;
  color: #1E2229;
  font-family: "Barlow", "Noto Sans JP", "Hiragino Kaku Gothic ProN", Meiryo, "MS PGothic", sans-serif;
  line-height: 0;
  line-height: 1.2;
}

input, textarea {
  color: #1E2229;
}

a {
  color: #00B695;
  transition: all 0.1s linear;
  text-decoration: none;
}
a:visited {
  color: #00B695;
}
a:hover {
  color: #FF7A00;
  opacity: 1;
  text-decoration: none;
}
a:active {
  color: #FF7A00;
}
a:focus {
  color: #FF7A00;
}

img {
  display: block;
}

span.emphasis{
  color: #ff2620;
  font-weight: bold;
}


/*
* ============================================
* レスポンシブ用
* ============================================
*/
.sp_only {
  display: none;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .sp_only {
    display: block;
  }
  .pc_only {
    display: none;
  }
}


/*
* ============================================
* アニメーション用
* ============================================
*/
.animated {
  opacity: 0;
}
.animated .fadeInUp {
  opacity: 1;
}

.animated:after {
  transition-delay: 2000ms;
  animation-name:fade-in1;
  animation-duration:4s;
  animation-timing-function: ease-out;
  animation-delay:1s;
  animation-iteration-count:1;
  animation-direction:normal;
  animation-fill-mode: forwards;
}


.show {
  transform-origin:center top;
  animation:show 1s both;
  animation-delay: 400ms;
}

.show:after {
  transform-origin:center top;
  animation:show 1s both!important;
  animation-delay: 600ms!important;
}

@keyframes show {
  0% {
    transform: translate(0,2em);
    opacity: 0;
    /* text-shadow:0 0 0 #0f0; */
    text-shadow: none;
  }
  50% {
    /* text-shadow:0 0 0.5em #0f0; */
    text-shadow: none;
  }
  100% {
    transform: translate(0,0);
    opacity: 1;
    text-shadow: none;
  }
}


/*
* ============================================
* ボタン
* ============================================
*/
.btn {
  font-size: 16px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #00B695;
  border: 0;
  border-radius: 40px;
  color: #fff !important;
  display: inline-block;
  line-height: 1;
  padding: 12px 20px;
  transition: all 0.1s linear;
  vertical-align: middle;
}

.btn:hover {
  background: #FF7A00;
}