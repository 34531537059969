/*
* 
*
* フッター
* 
*/


.footer {
  padding: 40px 0 0;
  font-size: 13px;
}

.footer .inner {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 30px;
}

.foot_info p {
  font-size: 18px;
  font-weight: 700;
}

.foot_info ul {
  margin-top: 17px;
  line-height: 22px;
}

.foot_menu ul {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 75px;
  font-weight: bold;
}

.foot_menu ul li a:hover{
  text-decoration: none;
  color: #00b695;
}

.copyright{
  text-align: center;
  font-size: 10px;
  margin-bottom: 12px;
}


/* タブレットサイズの調整 */
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .footer .inner {
    width: 90%;
  }
}


@media screen and (min-width: 0px) and (max-width: 767px) {
  .footer {
    padding: 25px 0 66px;
    font-size: 14px;
  }

  .footer .inner {
    width: 90%;
    margin: auto;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .foot_info p {
    font-size: 18px;
    font-weight: 700;
  }

  .foot_info ul {
    margin-top: 17px;
    line-height: 22px;
  }

  .foot_menu ul {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    gap: 16px;
    justify-content: space-between;
    margin-top: 45px;
    align-items: flex-start;
  }

  .foot_menu ul li a:hover {
    text-decoration: none;
    color: #00b695;
  }

  .copyright {
    text-align: center;
    font-size: 12px;
    margin-bottom: 14px;
  }
}