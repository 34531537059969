/*
* 
*
* メニュー
* 
*/


@keyframes slideIn {
  from {
    right: -375px;
  }
  to {
    right: 0;
  }
}

.menu {
  animation: slideIn 0.3s ease-out;
  background: #fff;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  right: 0;
  top: 0;
  width: 375px;
}

.menu__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 25px 20px 40px;
}

.menu__ttl {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.35em;
  line-height: 1;
}
.menu__ttl i {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.5em;
  margin-left: 0.5em;
}

.menu__close {
  font-size: 12px;
  align-items: center;
  -webkit-appearance: none;
      -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;
  color: #00B695;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: 6px;
  height: auto;
  line-height: 1;
  transition: all 0.1s linear;
  width: auto;
}
.menu__close:hover {
  color: #FF7A00;
}
.menu__close:hover .menu__close-ico {
  fill: #FF7A00;
}

.menu__close-ico {
  fill: #00B695;
  height: 20px;
  transition: all 0.1s linear;
  width: 20px;
}

.menu__list {
  margin: 35px;
}
.menu__list li {
  line-height: 1;
  margin-bottom: 26px;
}

.menu__btn,
a.menu__btn {
  font-size: 16px;
  -webkit-appearance: none;
      -moz-appearance: none;
          appearance: none;
  background: #fff;
  border: 1px solid #00B695;
  border-radius: 6px;
  color: #00B695;
  display: inline-block;
  font-weight: 700;
  line-height: 1;
  padding: 26px;
  text-align: center;
  transition: all 0.1s linear;
  width: 100%;
}
.menu__btn:hover,
a.menu__btn:hover {
  background: #FF7A00;
  border: 1px solid #FF7A00;
  color: #fff;
}

.menu__copyright {
  font-size: 14px;
  /* font-size: 14rem; */
  bottom: 30px;
  color: #9F9F9F;
  position: absolute;
  left: 0;
  line-height: 1;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .menu {
    width: 100vw;
  }
}

.dialog {
  border: 0;
  max-height: none;
  max-width: none;
  position: fixed;
  animation: fadeIn 0.1s ease-out;
  background: rgba(0, 151, 115, 0.7);
  height: 100vh;
  height: 100dvh;
  width: 100vw;
}

.dialog__wrapper {
  font-size: 14px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  height: -moz-fit-content;
  height: fit-content;
  inset: 0;
  margin: auto;
  max-height: 80vh;
  overflow: auto;
  position: fixed;
  width: 520px;
}

.dialog__header {
  margin: 40px 20px 30px;
  text-align: center;
}

.dialog__ttl {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.35em;
  line-height: 1;
  margin-bottom: 30px;
}

.dialog__body {
  margin: 0 30px 30px;
}

.dialog__action {
  display: flex;
  gap: 17px;
  justify-content: center;
  margin-bottom: 30px;
}

.dialog__close.-pc {
  font-size: 14px;
  display: block;
  margin: 0 auto 30px;
}
.dialog__close.-mobile {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .dialog__wrapper {
    animation: slideIn 0.2s ease-out;
    height: 100vh;
    height: 100dvh;
    max-height: 100vh;
    max-height: 100dvh;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
  }
  .dialog__header {
    margin: 0;
  }
  .dialog__ttl {
    border-bottom: 1px solid #D9D9D9;
    margin: 28px 0 30px;
    padding-bottom: 15px;
  }
  .dialog__lead {
    margin: -10px 25px 20px;
  }
  .dialog__body {
    margin: 0 25px 30px;
  }
  .dialog__close.-pc {
    display: none;
  }
  .dialog__close.-mobile {
    -webkit-appearance: none;
        -moz-appearance: none;
            appearance: none;
    background: transparent;
    border: 0;
    display: block;
    left: 18px;
    position: absolute;
    top: 28px;
  }
}