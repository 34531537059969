/*
* 
*
* ヘッダー
* 
*/


.header {
  background: #FFF;
  padding: 2%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 30;
  /* メニューボタン消した時の調整 */
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    height: 56px;
  }
}

.header img.top_logo {
  position: absolute;
  display: block;
  top: 17px;
  left: 27px;
  height: 46px;
  width: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    top: 11px;
    left: 27px;
    height: 36px;
    width: auto;
  }
}
  
.header-btn__wrapper {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: right;
}
  
.header__wrapper {
  align-items: center;
  -webkit-appearance: none;
      -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  line-height: 1;
  letter-spacing: -0.06em;
  pointer-events: auto;
  transition: all 0.1s linear;
  width: -moz-fit-content;
  width: fit-content;
}

button.header__wrapper:hover {
  opacity: 0.9;
}
  
@media only screen and (max-width: 480px) {
  .header-btn__wrapper a {
    display: none;
  }
}